#loader {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    z-index: 999999;
    background: var(--white_clr);
    opacity: 1;
    /* transition: all 0.3s ease-in-out 0s; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0s;
    /* transition: all 0.8s ease-in-out; */
}

#loader #spiner_bar {
    position: relative;
    width: 100px;
    height: 100px;
    background: var(--white_clr);
    border-radius: 50%;
    border: 10px solid var(--disabled_clr);
    display: flex;
    justify-content: center;
    align-items: center;
}

#loader #spiner_bar::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 80px;
    height: 80px;
    background: var(--white_clr);
    border: none;
    border-radius: 50%;
    transition: 0s !important;
    /* filter: drop-shadow(0px 0px 20px rgba(255, 0, 0, 0.5)); */
    /* filter: drop-shadow(0px 0px 20px rgba(174, 55, 93, 0.8)); */
}

#loader #spiner_bar #spiner {
    content: '';
    min-width: 100px;
    height: 100px;
    background: transparent;
    border: 10px solid transparent;
    border-radius: 50%;
    border-top-color: var(--main_clr);
    transform-origin: center;
    /* animation: spin 5s cubic-bezier(0.81, -0.92, 0, 1.92) 0s infinite alternate forwards; */
    animation: spin 3s cubic-bezier(0.81, -0.92, 0, 1.92) 0s infinite alternate forwards;
    filter: drop-shadow(0px 0px 20px rgba(174, 55, 93, 0.8));
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}







/* #loader #spiner {
    position: relative;
    width: 100px;
    height: 100px;
    background: var(--white_clr);
    border-radius: 50%;
    border: 10px solid var(--disabled_clr);
    display: flex;
    justify-content: center;
    align-items: center;
}

#loader #spiner::after {
    content: '';
    min-width: 80px;
    height: 80px;
    background: transparent;
    border: 10px solid transparent;
    border-radius: 50%;
    border-top-color: var(--main_clr);
    transform-origin: center;
    animation: spin 5s cubic-bezier(0.81, -0.92, 0, 1.92) 0s infinite alternate forwards;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */