@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*:root {
    --main_clr: rgb(174, 55, 93);
    --secondary_clr: #5c0f2b;
    --third_clr: rgb(138, 20, 56);
    --forth_clr: #FFCE70;
    --disabled_clr: #C5C5C5;
    --black_clr: #343434;
    --white_clr: #FFFFFF;
    --bright_clr: #F6F6F6;
    --light_black_clr: #767676;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--black_clr);
    /* transition: all 0.5s ease-in-out; */
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
}